import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Papa from 'papaparse';
import DropdownMenu from './DropdownMenu';

// Register the chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Ragnar',
        data: [],
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Contributed Capital',
        data: [],
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      }
    ],
  });

  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const fetchCSVData = async () => {
        const response = await fetch('/monthly_portval4.csv');
        const text = await response.text();
        const results = Papa.parse(text, { header: true });
        const labels = results.data.map(row => row.date);
        const ragnarData = results.data.map(row => Number(row.Ragnar));
        const contributedCapitalData = results.data.map(row => Number(row['Contributed Capital']));      

        setChartData({
          labels,
          datasets: [
            {
              label: 'Ragnar',
              data: ragnarData,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Contributed Capital',
              data: contributedCapitalData,
              borderColor: 'rgb(54, 162, 235)',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
            }
          ]
        });
      };

      fetchCSVData();
    }
  }, [isAuthenticated, isLoading]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Fund Performance Over Time',
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Value',
        }
      }
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-3">        
        <h3>Ragnar Fund 1 Dashboard</h3>
        <DropdownMenu />
      </div>
      <p>Welcome back {user.name}</p>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Line data={chartData} options={options} />
      )}
    </div>
  );
}

export default Dashboard;
