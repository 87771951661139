import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Portfolio() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the portfolio.</div>;
    }

    const holdings = [
        { equities: "SoFi Technologies", ticker: "SOFI", dividendYield: "--", costBasis: "$39,997", reinvestedDividends: "--", cashDividends: "--", marketValue: "$84,659", portfolioPercent: "18.9%", totalgainloss: "112%" },
        { equities: "Comtech Telecommunications Corp", ticker: "CMTL", dividendYield: "--", costBasis: "$54,950", reinvestedDividends: "--", cashDividends: "--", marketValue: "$67,800", portfolioPercent: "15.2%", totalgainloss: "23%" },
        { equities: "AST SpaceMobile", ticker: "ASTS", dividendYield: "--", costBasis: "$10,808", reinvestedDividends: "--", cashDividends: "--", marketValue: "$59,525", portfolioPercent: "13.3%", totalgainloss: "451%" },        
        { equities: "AGNC Investment Corp", ticker: "AGNC", dividendYield: "13.8%", costBasis: "$51,644", reinvestedDividends: "$9,767", cashDividends: "$360", marketValue: "$56,773", portfolioPercent: "12.7%", totalgainloss: "11%" },       
        { equities: "PubMatic", ticker: "PUBM", dividendYield: "--", costBasis: "$49,995", reinvestedDividends: "--", cashDividends: "--", marketValue: "$42,634", portfolioPercent: "9.5%", totalgainloss: "(15%)" },        
        { equities: "Pinterest", ticker: "PINS", dividendYield: "--", costBasis: "$30,089", reinvestedDividends: "--", cashDividends: "--", marketValue: "$39,962", portfolioPercent: "8.9%", totalgainloss: "33%" },
        { equities: "Alphabet", ticker: "GOOGL", dividendYield: "0.5%", costBasis: "$19,940", reinvestedDividends: "--", cashDividends: "--", marketValue: "$20,274", portfolioPercent: "4.5%", totalgainloss: "2%" },        
        { equities: "Equities Total", ticker: "--", dividendYield: "3.2%", costBasis: "$257,421", reinvestedDividends: "$9,767", cashDividends: "$360", marketValue: "$371,627", portfolioPercent: "83.1%", totalgainloss: "45%" },
        { equities: "Cash", ticker: "--", dividendYield: "--", costBasis: "--", reinvestedDividends: "--", cashDividends: "--", marketValue: "$75,700", portfolioPercent: "16.9%", totalgainloss: "--" },
        { equities: "Portfolio Value", ticker: "--", dividendYield: "2.6%", costBasis: "$332,000", reinvestedDividends: "--", cashDividends: "--", marketValue: "$447,327", portfolioPercent: "100.0%", totalgainloss: "35%" }
    ];


    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Portfolio Holdings (as of 11/29/24)</h3>
                <DropdownMenu />  
            </div>    
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Equities</th>
                        <th>Ticker</th>
                        <th>Dividend Yield</th>
                        <th>Cost Basis</th>
                        <th>Market Value</th>
                        <th>% of Portfolio</th>
                        <th>Total % gain/loss</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.map((holding, index) => (
                        <tr key={index}>
                            <td>{holding.equities}</td>
                            <td>{holding.ticker}</td>
                            <td>{holding.dividendYield}</td>
                            <td>{holding.costBasis}</td>
                            <td>{holding.marketValue}</td>
                            <td>{holding.portfolioPercent}</td>
                            <td>{holding.totalgainloss}</td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    );
}

export default Portfolio;
